import React, { lazy, Suspense } from "react";
import { Route, Switch } from 'react-router-dom';
import Loading from './components/Loading';

function App() {
  return (
    <Suspense fallback={<Loading cover='content' />}>
      <Switch>
        <Route exact path='/' component={lazy(() => import('./pages/Home'))} />
        <Route exact path='/reply' component={lazy(() => import('./pages/Reply'))} />
        <Route exact path='/error' component={lazy(() => import('./pages/Error'))} />
        <Route exact path='/logout' component={lazy(() => import('./pages/Logout'))} />
        <Route exact path='/mail/:email' component={lazy(() => import('./pages/Mail'))} />
        <Route exact path='/verify-otp' component={lazy(() => import('./pages/VerifyOtp'))} />
        <Route exact path='/viewer/:metadata' component={lazy(() => import('./pages/AttachmentViewer'))} />
        <Route exact path='/viewer' component={lazy(() => import('./pages/FileAttachmentViewer'))} />
        <Route exact path='/file-viewer' component={lazy(() => import('./pages/FileViewer'))} />
        <Route exact path='/authenticate' component={lazy(() => import('./pages/Authenticate'))} />
        <Route exact path='/session-expired' component={lazy(() => import('./pages/SessionExpired'))} />
        <Route exact path='/test-viewer' component={lazy(() => import('./components/TestViewer'))} />
      </Switch>
    </Suspense>
  );
}

export default App;
