import { createContext, useState } from 'react';

const ContentContext = createContext({
  content: null,
  loggedInEmail: '',
  contentPrivacy: null,
  storeContent: function (contentData) { },
  setLoggedInEmail: function (email) { },
  setContentPrivacy: function (privacy) { }
});

export function ContentContextProvider(props) {

  const [activeContent, setActiveContent] = useState();
  const [activeEmail, setActiveEmail] = useState();
  const [activeContentPrivacy, setActiveContentPrivacy] = useState();

  function storeContentHandler(contentData) {
    setActiveContent(contentData);
  }

  function setLoggedInEmailHandler(email) {
    setActiveEmail(email);
  }

  function storeContentPrivacyHandler(privacy) {
    setActiveContentPrivacy(privacy)
  }


  const context = {
    content: activeContent,
    loggedInEmail: activeEmail,
    contentPrivacy: activeContentPrivacy,
    storeContent: storeContentHandler,
    setLoggedInEmail: setLoggedInEmailHandler,
    setContentPrivacy: storeContentPrivacyHandler
  };

  return (
    <ContentContext.Provider value={context}>
      {props.children}
    </ContentContext.Provider>
  );
}

export default ContentContext;
